  $(document).ready(function(){
    console.log("running");
        var pathname = window.location.hash;
        console.log(pathname);
        if(pathname.indexOf('preorder') > -1){
            console.log(pathname.indexOf('preorder'));
             setTimeout(function() {
              $(".buy-button")[0].click();
              console.log("buy button clicked");
             },1000);
            }
  });